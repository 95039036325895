/* eslint-disable @angular-eslint/component-selector */
import {Component, ViewChild} from '@angular/core';
import {SessionService} from '../../shared/session/session.service';
import {MarketPartnerService} from '../../shared/services/marketpartner.service';

import {ActivatedRoute, Router} from '@angular/router';
import {SmartFormBuilder} from '../../shared/marketpartner-components/smart-forms/smart-form/builder/smart-form.builder';
import {SmartFormConfigDefinition} from '../../shared/marketpartner-components/smart-forms/smart-form/classes/SmartFormConfigDefinition';

import {SmartFormComponent} from '../../shared/marketpartner-components/smart-forms/smart-form/component/smart-form.component';
import {MarketpartnerViewComponent} from '../shared/marketpartner-view.component';
import {ApiRequestService} from '../../shared/api-request/service/api-request.service';
import {ApiRequestBuilder} from '../../shared/api-request/builder/api-request.builder';
import {USERS_API_ENDPOINTS_LIST} from '../requests/api-endpoints-list';
import {NameValueMap} from '../../shared/marketpartner-components/smart-forms/smart-shared/classes/KeyValueMap';

@Component({
  selector: 'page-users-marketpartner-communication-data-content',
  templateUrl: './users-marketpartner-communication-data.component.html',
  styleUrls: ['../users-common.scss']
})


export class MarketpartnerCommunicationDataComponent extends MarketpartnerViewComponent  {
  @ViewChild('baseDataForm')  baseDataForm?: SmartFormComponent;

  initialized = false;
  baseDataFormConfig?: SmartFormConfigDefinition;

  constructor(public sessionService: SessionService,
              public marketPartnerService: MarketPartnerService,
              public activatedRoute: ActivatedRoute,
              public apiRequestService: ApiRequestService,
              public router: Router) {
    super(activatedRoute, router, sessionService, marketPartnerService, apiRequestService);
  }

  async onParamsUrlInitialized(params: any) {
    await super.onParamsUrlInitialized(params);

    if (this.marketPartnerInfo && this.partnerId) {
      let form = new SmartFormBuilder();
      if (this.isOperatorMode) {
        form.addFormFieldMarketPartnerName();
      } else {
        form.addFormFieldMarketPartnerNameReadOnly()
      }
      form
        .addFormFieldMarketPartnerEICReadOnly();
      if (this.marketPartnerRole === 'VNB') {
        if (this.isOperatorMode) {
          form.addFormFieldVseIdentificator();
        } else {
          form.addFormFieldVseIdentificatorReadOnly();
        }
      }
      form
        .addFormFieldMarketPartnerSDATRoleReadOnly()
        .addFormFieldMarketPartnerContractStatusHidden()  // FIXME: to be removed, after backend is fixed to disallow it
        .addFormFieldMarketPartnerContactEmail()
        .addFormFieldMarketPartnerContactPhone()
        .addFormFieldMarketPartnerLanguage()
        .addFormFieldMarketPartnerAutoSyntaxCheckForIncomingMessages()
        .setApiSendDataRequestConfigFromInfo(
          new ApiRequestBuilder().setEndpointInfo(
            USERS_API_ENDPOINTS_LIST.marketpartnerDirectoryMarketPartnerSettingsUpdate, {partnerId: this.partnerId}
          ).build()
        )
        .setInitData({partnerSettings: this.marketPartnerInfo.partnerSettings})
        .showSubmitButton(true)
        .showCancelButton(false);
      this.baseDataFormConfig = form.build();
    }

    this.setInitialized(true);
  }

  onSuccessFormValue(_formValue: NameValueMap<any>) {

  }

}
