import { SMART_FORM_DEFS } from '../definitions/smart-form.definitions';

import {SMART_FORM_LAYOUTLABEL_DEFS} from '../definitions/smart-form-layoutlabel.definitions';
import {SMART_FORM_FORMFIELD_DEFS} from '../definitions/smart-form-formfields.definitions';
import {SmartToolbarActionDefinition} from '../../smart-shared/classes/SmartToolbarActionDefinition';
import {SmartFormLayoutLabelDefinitionContainer} from '../classes/SmartFormLayoutLabelDefinition';
import {SmartFormFormFieldDefinitionContainer} from '../classes/SmartFormFormFieldDefinition';
import {SmartFormFormFieldDefinition} from '../classes/SmartFormFormFieldDefinition';
import {SmartFormConfigDefinition} from '../classes/SmartFormConfigDefinition';
import {SmartFormButtonDefinition} from '../classes/SmartFormButtonDefinition';
import {ApiRequestConfigDefinition} from '../../../../api-request/classes/ApiRequestConfigDefinition';
import {EnumItem} from '../../../../services/enum-utils/classes/enum-definition';
import {NameValueMap} from '../../smart-shared/classes/KeyValueMap';
import { ValidatorFn, AbstractControl } from '@angular/forms';

export class SmartFormBuilder {
  private _formConfig: SmartFormConfigDefinition = {
    onSuccessMessage: {
      type: 'success',
      message: 'RequestSuccessfullyCompleted',
      showAsNotification: true
    },
    cssClassesByType: {
      layoutLabelInput: {
        'label': ' col-12 ',
        'input': ' col-12 '
      },
      layoutGeneral: {
        'formContainer': '',
        'fieldContainer': ' col-sm-6 '
      },
    },
    title: '',
    initData: {},
    includeUnusedInitDataInOutput: false,
    removeEmptyValuesFromOutput: false,
    apiGetDataRequestConfig: undefined,
    apiSendDataRequestConfig: undefined,
    buttons: [
      {
        id: 'downloadButton',
        title: 'ButtonDownload',
        icon: 'pli-download',
        visible: false,
        type: 'download',
        cssClasses: 'btn btn-default',
      },
      {
        id: 'cancelButton',
        title: 'commonButtonsNames.cancel',
        icon: undefined,
        visible: false,
        type: 'cancel',
        cssClasses: 'btn btn-default'
      },
      {
        id: 'submitButton',
        title: 'ButtonSave',
        icon: undefined,
        visible: true,
        type: 'submit',
        cssClasses: 'btn btn-primary'
      }
    ],
    fields : [],
    formValidators: [],
    actionsToolbar: [],
    showStarOnRequiredField: true,
    showActionsToolbarIfEmpty: false,
    showInvalidFieldsAlways: false,
    initialErrorMessage: '',
    objectsArrayDefinitions: {}
  };


  private _allDefinitions: NameValueMap<any> = {};
  constructor(initConfig?: SmartFormConfigDefinition) {
    this._allDefinitions = SMART_FORM_DEFS;

    if (initConfig) {
      this._formConfig = initConfig;
    }

    this.importFormFormFieldsDefs(SMART_FORM_FORMFIELD_DEFS);
    this.importFormLayoutLabelsDefs(SMART_FORM_LAYOUTLABEL_DEFS);
  }

  protected importFormFormFieldsDefs(toImport: SmartFormFormFieldDefinitionContainer[]) {
    this.importDefinitionsItems(toImport, 'formFields');
  }

  protected importFormLayoutLabelsDefs(toImport: SmartFormLayoutLabelDefinitionContainer[]) {
    this.importDefinitionsItems(toImport, 'layoutLabelInput');
  }

  private importDefinitionsItems(toImport: any[], category: string) {
    const self = this;
    if (!self._allDefinitions[category]) {
      self._allDefinitions[category] = {};
    }
    toImport.forEach(function(thisEntry) {
      self._allDefinitions[category][thisEntry.id] =  thisEntry.definition;
    });
  }

  public setInitData(initData: NameValueMap<any>, includeUnusedInitDataInOutput = false): SmartFormBuilder {
    if (includeUnusedInitDataInOutput) {
      console.warn('depreciated: includeUnusedInitDataInOutput = true is usually a bad idea');
    }
    this._formConfig.initData = initData;
    this._formConfig.includeUnusedInitDataInOutput = includeUnusedInitDataInOutput;
    return this;
  }

  public setLayoutLabelInputFromId(layoutId: string): SmartFormBuilder {
    this._formConfig.cssClassesByType.layoutLabelInput = SMART_FORM_DEFS.layoutLabelInput[layoutId];
    return this;
  }

  public setLayoutGeneralFieldContainerValue(value: string): SmartFormBuilder {
    this._formConfig.cssClassesByType.layoutGeneral.fieldContainer = value;
    return this;
  }

  public showInvalidFieldsAlways(showInvalidFields: boolean): SmartFormBuilder {
    this._formConfig.showInvalidFieldsAlways = showInvalidFields;
    return this;
  }

  public showActionsToolbarIfEmpty(showActionsToolbarIfEmpty: boolean): SmartFormBuilder {
    this._formConfig.showActionsToolbarIfEmpty = showActionsToolbarIfEmpty;
    return this;
  }

  public setFormTitleFromText(title: string): SmartFormBuilder {
    this._formConfig.title = title;
    return this;
  }

  public setObjectsArrayDefinitions(objectsArrayDefinitions: NameValueMap<SmartFormFormFieldDefinition[]>) {
    if (objectsArrayDefinitions) {
      this._formConfig.objectsArrayDefinitions = objectsArrayDefinitions;
    }
    return this;
  }

  public setSubmitButtonText(text: string): SmartFormBuilder {
    const buttonInfo = this.getButtonInfo('submitButton');
    if (buttonInfo) {
      buttonInfo.title = text;
    }
    return this;
  }

  public setApiSendDataRequestConfigFromInfo(apiRequestConfig: ApiRequestConfigDefinition) {
    this._formConfig.apiSendDataRequestConfig = apiRequestConfig;
    return this;
  }

  public setApiGetDataRequestConfigFromInfo(apiRequestConfig: ApiRequestConfigDefinition) {
    this._formConfig.apiGetDataRequestConfig = apiRequestConfig;
    return this;
  }

  public removeEmptyValuesFromOutput(value: boolean) {
    this._formConfig.removeEmptyValuesFromOutput = value;
    return this;
  }

  public addFormFieldFromIdWithLocalList(formFieldId: string, defaultValue?: any, listOptions?: EnumItem[]) {
    const def: SmartFormFormFieldDefinition = {
      ...SMART_FORM_DEFS.formFields[formFieldId],
      default: defaultValue,
      allowedValues: {
        type: 'localDefined',
        data: listOptions
      },
    };
    this._formConfig.fields.push(def);
    return this;
  }

  public addFormFieldFromId(formFieldId: string, defaultValue?: any): SmartFormBuilder {
    const formFieldInfo: SmartFormFormFieldDefinition = {
      ...SMART_FORM_DEFS.formFields[formFieldId]
    };
    if (defaultValue !== undefined) {
      formFieldInfo.default = defaultValue;
    }
    this._formConfig.fields.push(formFieldInfo);
    return this;
  }

  public addFormFieldFromIdDisabled(formFieldId: string, disabled: boolean, defaultValue?: any): SmartFormBuilder {
    const formFieldInfo: SmartFormFormFieldDefinition = {
      ...SMART_FORM_DEFS.formFields[formFieldId]
    };
    if (defaultValue !== undefined) {
      formFieldInfo.default = defaultValue;
    }
    formFieldInfo.disabled = disabled;
    this._formConfig.fields.push(formFieldInfo);
    return this;
  }

  public addFormFieldFromIdWithTitle(formFieldId: string, title: string): SmartFormBuilder {
    const def = {
      ...SMART_FORM_DEFS.formFields[formFieldId],
      title
    };
    this._formConfig.fields.push(def);
    return this;
  }

  public addFormFieldFromInfo(formFieldInfo: SmartFormFormFieldDefinition): SmartFormBuilder {
    this._formConfig.fields.push(formFieldInfo);
    return this;
  }

  public addReadOnly(formFieldInfo: SmartFormFormFieldDefinition): SmartFormBuilder {
    this.addFormFieldFromInfo({
      disabled: true,
      readOnly: true,
      validation: {required: false},
      ...formFieldInfo
    });
    return this;
  }

  public addRequired(formFieldInfo: SmartFormFormFieldDefinition): SmartFormBuilder {
    const validation = {
      required: true,
      ...(formFieldInfo.validation || {})
    };
    this.addFormFieldFromInfo({
      ...formFieldInfo,
      validation
    });
    return this;
  }

  public addOptional(formFieldInfo: SmartFormFormFieldDefinition): SmartFormBuilder {
    return this.addFormFieldFromInfo(formFieldInfo);
  }

  public addFormValidator(validator: ValidatorFn) {
    this._formConfig.formValidators.push(validator);
    return this;
  }

  public addOptionalPair(formFieldInfoA: SmartFormFormFieldDefinition, formFieldInfoB: SmartFormFormFieldDefinition, translatableError: string) {
    this.addOptional(formFieldInfoA);
    this.addOptional(formFieldInfoB);
    this.addFormValidator((formGroup: AbstractControl) => {
      const a = formGroup.get(formFieldInfoA.name);
      const b = formGroup.get(formFieldInfoB.name);
      if (!a || !b) throw new Error('missing form fields');
      // clear previous errors (if any) by re-running EIC validation
      a.setErrors(a.validator ? a.validator(a) : null);
      b.setErrors(b.validator ? b.validator(b) : null);
      if (b.value && !a.value) {
        a.setErrors({ translatableMessage: translatableError});
      } else if (a.value && !b.value) {
        b.setErrors({ translatableMessage: translatableError});
      }
      return null;
    });
    return this;
  }

  public addToolbarActionFromId(actionId: string): SmartFormBuilder {
    this._formConfig.actionsToolbar.push({...SMART_FORM_DEFS.toolbarActions[actionId]});
    return this;
  }
  public addToolbarActionFromInfo(actionInfo: SmartToolbarActionDefinition): SmartFormBuilder {
    this._formConfig.actionsToolbar.push(actionInfo);
    return this;
  }

  public showSubmitButton(show: boolean, title?: string ): SmartFormBuilder {
    const buttonInfo = this.getButtonInfo('submitButton');
    if (buttonInfo) {
      buttonInfo.visible = show;
      if (title !== undefined) {
        buttonInfo.title = title;
      }
    }
    return this;
  }

  public showDownloadButton(show: boolean, title?: string ): SmartFormBuilder {
    const buttonInfo = this.getButtonInfo('downloadButton');
    if (buttonInfo) {
      buttonInfo.visible = show;
      if (title !== undefined) {
        buttonInfo.title = title;
      }
    }
    return this;
  }

  public setSubmitDangerous(isDangerous: boolean) {
    const buttonInfo = this.getButtonInfo('submitButton');
    if (buttonInfo && isDangerous) {
      buttonInfo.cssClasses = 'btn btn-danger';
    }
    return this;
  }

  public resetFormOnSubmitError(resetOnError: boolean): SmartFormBuilder {
    this._formConfig.resetFormFieldsOnError = resetOnError;
    return this;
  }

  public showCancelButton(show: boolean, title?: string): SmartFormBuilder {
    const buttonInfo = this.getButtonInfo('cancelButton');
    if (buttonInfo) {
      buttonInfo.visible = show;
      if (title !== undefined) {
        buttonInfo.title = title;
      }
    }
    return this;
  }

  private getButtonInfo(buttonId: string): SmartFormButtonDefinition | undefined {
    let toReturn;
    if (this._formConfig.buttons) {
      this._formConfig.buttons.forEach(function(buttonInfo: SmartFormButtonDefinition) {
        if (buttonInfo.id === buttonId) {
          toReturn = buttonInfo;
        }
      });
    }
    return toReturn;
  }

  public addButtonFromId(buttonId: string,  insertAt: string = 'start'): SmartFormBuilder {
    return this.addButtonFromInfo(SMART_FORM_DEFS.buttons[buttonId], insertAt);
  }

  public addButtonFromInfo(buttonInfo: SmartFormButtonDefinition, insertAt: string = 'start'): SmartFormBuilder {
    if (insertAt === 'start') {
      this._formConfig.buttons.unshift(buttonInfo);
    } else {
      this._formConfig.buttons.push(buttonInfo);
    }
    return this;
  }


  public setInitialErrorMessage(msg: string): SmartFormBuilder {
    this._formConfig.initialErrorMessage = msg;
    return this;
  }

  public build(): SmartFormConfigDefinition {
    return this._formConfig;
  }

  public addFormFieldMarketPartnerAutoSyntaxCheckForIncomingMessages( defaultValue?: boolean): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldMarketPartnerAutoSyntaxCheckForIncomingMessages', defaultValue);
  }

  public addFormFieldMarketPartnerNameReadOnly( defaultValue?: string): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldMarketPartnerNameReadOnly', defaultValue);
  }

  public addFormFieldMarketPartnerName( defaultValue?: string): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldMarketPartnerName', defaultValue);
  }

  public addFormFieldMarketPartnerSDATRole( defaultValue?: string): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldMarketPartnerSDATRole', defaultValue);
  }

  public addFormFieldMarketPartnerContractStatus( defaultValue?: string): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldMarketPartnerContractStatus', defaultValue);
  }

  public addFormFieldMarketPartnerContractStatusHidden( defaultValue?: string): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldMarketPartnerContractStatusHidden', defaultValue);
  }

  public  addFormFieldMarketPartnerLanguage( defaultValue?: string): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldMarketPartnerLanguage', defaultValue);
  }

  public addFormFieldMarketPartnerInitialAdminEmail( defaultValue?: string): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldMarketPartnerInitialAdminEmail', defaultValue);
  }

  public addFormFieldMarketPartnerSDATRoleDisabled( defaultValue?: string): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldMarketPartnerSDATRoleDisabled', defaultValue);
  }

  public addFormFieldMarketPartnerSDATRoleReadOnly( defaultValue?: string): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldMarketPartnerSDATRoleReadOnly', defaultValue);
  }

  public addFormFieldMarketPartnerContactEmail( defaultValue?: string): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldMarketPartnerContactEmail', defaultValue);
  }

  public addFormFieldMarketPartnerContactEmailReadOnly( defaultValue?: string): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldMarketPartnerContactEmailReadOnly', defaultValue);
  }

  public addFormFieldMarketPartnerContactEmailHidden( defaultValue?: string): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldMarketPartnerContactEmailHidden', defaultValue);
  }

  public addFormFieldMarketPartnerContactPhone( defaultValue?: string): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldMarketPartnerContactPhone', defaultValue);
  }

  public addFormFieldMarketPartnerContactPhoneReadOnly( defaultValue?: string): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldMarketPartnerContactPhoneReadOnly', defaultValue);
  }

  public addFormFieldFirstname( defaultValue?: string): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldFirstname', defaultValue);
  }
  public addFormFieldFirstnameReadOnly( defaultValue?: string): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldFirstnameReadOnly', defaultValue);
  }

  public addFormFieldSalutation( defaultValue?: string): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldSalutation', defaultValue);
  }
  public addFormFieldSalutationReadOnly( defaultValue?: string): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldSalutationReadOnly', defaultValue);
  }

  public addFormFieldTelephone( defaultValue?: string): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldTelephone', defaultValue);
  }
  public addFormFieldTelephoneReadOnly( defaultValue?: string): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldTelephoneReadOnly', defaultValue);
  }

  public addFormFieldLastname( defaultValue?: string): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldLastname', defaultValue);
  }

  public addFormFieldLastnameReadOnly( defaultValue?: string): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldLastnameReadOnly', defaultValue);
  }

  public addFormFieldAccountTypeWithoutMarketPartner( defaultValue?: string): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldAccountTypeWithoutMarketPartner', defaultValue);
  }

  public addFormFieldAccountTypeMarketPartnerReadOnly( defaultValue?: string): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldAccountTypeMarketPartnerReadOnly', defaultValue);
  }

  public addFormFieldScopes( defaultValue?: string): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldScopes', defaultValue);
  }

  public addFormFieldMarketPartnerSelection( defaultValue?: string): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldMarketPartnerSelection', defaultValue);
  }

  public addFormFieldMarketPartnerClientReadOnly( defaultValue?: string): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldMarketPartnerClientReadOnly', defaultValue);
  }

  public addFormFieldEmail( defaultValue?: string): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldEmail', defaultValue);
  }

  public addFormFieldEmailReadOnly( defaultValue?: string): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldEmailReadOnly', defaultValue);
  }

  public addFormFieldOldPassword( ): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldOldPassword');
  }

  public addFormFieldNewPassword( ): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldNewPassword');
  }

  public addFormFieldNewPasswordConfirm( ): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldNewPasswordConfirm');
  }

  public addFormFieldMarketPartnerEICReadOnly(  defaultValue?: string ): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldMarketPartnerEICReadOnly', defaultValue);
  }

  public addFormFieldMarketPartnerSenderEICReadOnly(  defaultValue?: string ): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldMarketPartnerSenderEICReadOnly', defaultValue);
  }

  public addFormFieldMarketPartnerSenderRole(  defaultValue?: string): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldMarketPartnerSenderRole', defaultValue);
  }

  public addFormFieldMarketPartnerSenderRoleReadOnly(  defaultValue?: string): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldMarketPartnerSenderRoleReadOnly', defaultValue);
  }

  public addFormFieldFTPAddress(  defaultValue?: string ): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldFTPAddress', defaultValue);
  }

  public addFormFieldFTPDirectory(  defaultValue?: string ): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldFTPDirectory', defaultValue);
  }

  public addFormFieldFTPUsername( defaultValue?: string): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldFTPUsername', defaultValue);
  }

  public addFormFieldFTPPassword(  defaultValue?: string): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldFTPPassword', defaultValue);
  }

  public addFormFieldSDATWebClientForAll(disabled: boolean, defaultValue?: string): SmartFormBuilder {
    return this.addFormFieldFromIdDisabled('formFieldSDATWebClientForAll', disabled, defaultValue);
  }

  public addFormFieldVseIdentificator(defaultValue?: string): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldVseIdentificator', defaultValue);
  }

  public addFormFieldVseIdentificatorReadOnly(defaultValue?: string): SmartFormBuilder {
    return this.addFormFieldFromId('formFieldVseIdentificatorReadOnly', defaultValue);
  }
}
